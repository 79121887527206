var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-full grid h-96 justify-item-center font-extrabold text-white text-center content-center",style:({
        backgroundImage: 'url(/bg_js.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backdropFilter: 'blur(10px)',
    })},[_c('h1',{staticClass:"text-8xl"},[_vm._v("Press Room")])]),_c('div',{staticClass:"container py-8 px-6 item-center mx-auto px-6 pt-4 pb-6",attrs:{"id":"news"}},[_c('div',{staticClass:"grid grid-cols-3 p-4 py-8"},_vm._l((_vm.dataList),function(sliderN){return _c('div',{key:sliderN.title,staticClass:"col-span overflow-hidden"},[_c('div',{staticClass:"relative"},[_c('img',{staticClass:"object-cover h-64 w-full hover:scale-125",attrs:{"src":sliderN.image,"alt":sliderN.title}}),_c('div',{staticClass:"absolute bottom-0 left-0 py-4 px-4 text-white bg-gray-800 bg-opacity-25 w-full"},[_c('span',{staticClass:"my-2 text-sm"},[_vm._v(_vm._s(_vm.$moment(sliderN.created_at).format("MMMM Mo, YYYY")))]),_c('h3',{staticClass:"text-xl break-all font-bold truncate"},[_vm._v(" "+_vm._s(sliderN.title)+" ")]),_c('span',{staticClass:"mt-4 text-sm hover:underline cursor-pointer",on:{"click":function($event){return _vm.openDetailPage(sliderN)}}},[_vm._v("Read More")])])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }